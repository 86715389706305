import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";

import frontImage from "images/face/faceproducts/moisture-boost-serum.webp";
import frontCapImage from "images/face/faceproducts/moisture-boost-serum-capoff.webp";
import backImage from "images/face/faceproducts/moisture-boost-serum-back.webp";
import frontZoomImage from "images/face/faceproducts/moisture-boost-serum.webp";
import frontCapZoomImage from "images/face/faceproducts/moisture-boost-serum-capoff.webp";
import backZoomImage from "images/face/faceproducts/moisture-boost-serum-back.webp";

import img2 from "images/face/faceproducts/moisture-boost-serum-chart.webp";

import img6 from "images/callouts/eucerin-face-product-family.webp";
import img7 from "images/callouts/cout-footer-daily-skin.webp";
//import img8 from "images/callouts/cout-footer-facecare.webp";
import img8 from "images/callouts/sunproducts2x.webp";
import "./serum.scss";

function EucerinFaceImmersiveHydrationNightCreamPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      const myHash = window.location.hash.slice(1);
      const myScrollSpot = document.getElementById(myHash);
      if (myScrollSpot) {
        const currentBlock = myScrollSpot.getBoundingClientRect();
        window.scrollTo(0, currentBlock.top - 150);
      }
    }, 500);
  }, []);

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-age-defense page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Face Immersive Hydration Moisture Boost Serum"
        pageDescription=""
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#106E9E"
              categoryName="FACE CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Face Immersive Hydration
              Moisture Boost Serum"
              productSlider={[frontImage, frontCapImage, backImage]}
              zoomImages={[frontZoomImage, frontCapZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Boost the routine with concentrated hyaluronic acid (HA)
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Face Immersive Hydration Moisture Boost Serum
                  </span>{" "}
                  is enriched with 3X concentrated HA* in an ultra-lightweight
                  formulation for immediate and 24-hour hydration. With daily
                  use, skin looks more radiant and feels soft, supple, and more
                  firm.
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      Visibly improves the appearance of fine lines in as little
                      as 4 weeks
                    </span>
                  </li>
                  <li>
                    <span>
                      Apply before Eucerin<sup>&reg;</sup> Face Immersive
                      Hydration Daily Lotion SPF 30 and{" "}
                      <nobr>
                        Eucerin<sup>&reg;</sup> Face
                      </nobr>{" "}
                      Immersive Hydration Night Cream
                    </span>
                  </li>
                </ul>
                <p className="references-symbols last">
                  *Vs Eucerin Face Immersive Hydration Daily Lotion SPF 30.
                </p>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Multi-weight hyaluronic acid
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      Intense moisture hydrates deep within the surface layers
                      of the skin
                    </span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">Antioxidant</p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Vitamin C (Sodium Ascorbyl Phosphate)
                      </span>{" "}
                      helps guard against free radicals
                    </span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold oceanicblue-text sub-title">
                    Formulated for all skin types
                  </p>
                  <ul className="checkmarks oceanicblue">
                    <li>
                      <span>NON-COMEDOGENIC</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>

        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="HYDRATION ASSESSMENT"
              productThemeColor="oceanic-blue"
              id="hydrationassessment"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Corneometer Assessment of Moisture boost serum providing immediate and 24-hour moisture"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Duration of
                    2 weeks to test moisturization efficacy following
                    application of Moisture Boost Serum. Female patients (n=33),
                    aged 25 to 55, applied product on the inner forearm after
                    baseline assessments and returned for evaluation at 2, 24,
                    and 48 hours. Thereafter, application of product was 2 times
                    daily with evaluations at 1- and 2-week timepoints. Skin
                    moisturization was assessed by Corneometer<sup>&reg;</sup>{" "}
                    CM 825.
                  </p>
                  <p
                    className="references-symbols last"
                    style={{ textIndent: "-10px" }}
                  >
                    **Statistically significant improvement compared to baseline{" "}
                    (<span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references-symbols last">
                    <sup>&Dagger;</sup>Statistically significant compared to
                    untreated control (
                    <span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references-symbols last">
                    <sup>&dagger;</sup>Statistically significant compared to
                    untreated control (
                    <span className="font-italic">p&lt;</span>0.05).
                  </p>
                  <p className="references last">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Provided immediate and <nobr>24-hour</nobr> moisture
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Eucerin face family product line"
                  caption="Find resources to support your face care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img8}
                  imgAlt="EXPLORE SUN CARE"
                  caption=" Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EucerinFaceImmersiveHydrationNightCreamPage;
